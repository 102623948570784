import React from "react";
import {Seo} from "components/seo";

import { graphql } from "gatsby";
import { globalQuery } from "types/graphql";

import Anchors from "components/sections/anchors";
import HeroImage from "components/sections/hero";
import ImageContent from "components/sections/imageContent";
import ListTitles from "components/sections/listTitles";
import TabSkills from "components/sections/tabSkills";
import TabTechnos from "components/sections/tabTechnos";
import ListBannerContent from "components/sections/listBannerContent";
import ArrowTitle from "components/sections/arrowTitle";
import {StaticImage} from "gatsby-plugin-image";
import TitleImageIntro from "components/sections/titleImageIntro";

export default function Offer({ data }: { data: globalQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
  } = data;

  return (
    <>
      {sectionsData.map(
        ({ node: { frontmatter: elem, html } }, index: number) => {
          if (elem.format == "title") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription} />
                <TitleImageIntro title={elem.title} content={html}>
                  <div key={index} className="transition-image row-start-1 row-start-1 col-start-8 col-span-full opacity-20 " data-aos="custom">
                    <StaticImage
                      layout="fullWidth"
                      aspectRatio={1.2/1}
                      objectFit={"cover"}
                      alt="Echange membres équipe La Fabrique VingtCinq"
                      src={'../../static/offer/VingtCinq-Fabrique-digitale-vingt-cinq-days-team-visio-conference.jpg'}
                    />
                    <span className="transition-layer" data-key={index} style={{transitionDelay: '0.3s'}}/>
                  </div>
                </TitleImageIntro>
              </React.Fragment>
            );          }
          if (elem.format == "anchors") {
            return <Anchors key={index} listtitle={elem.listtitle} />;
          }
          if (elem.format == "banner") {
            return (
              <ImageContent
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                content={html}
                imageUrl={elem.imageUrl}
                parallaxImages={elem.parallaxImages}
              />
            );
          }
          if (elem.format == "arrowtitle") {
            return <ArrowTitle key={index} title={elem.title} />;
          }
          if (elem.format == "titles") {
            return (
              <ListTitles
                key={index}
                titles={elem.titles}
                content={html}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
              />
            );
          }
          if (elem.format == "tabs") {
            return (
              <TabSkills
                key={index}
                id="expertises"
                title={elem.title}
                listicon={elem.listicon}
                listtitle={elem.listtitle}
              />
            );
          }
          if (elem.format == "listbannercontent") {
            return (
              <ListBannerContent
                key={index}
                id="solutions"
                parallaxImages={elem.parallaxImages}
                title={elem.title}
              />
            );
          }
          if (elem.format == "tabstechnos") {
            return (
              <TabTechnos
                key={index}
                id="technos"
                title={elem.title}
                listicon={elem.listicon}
                listtitle={elem.listtitle}
              />
            );
          }
          if (elem.format == "herotext") {
            return (
              <HeroImage
                key={index}
                title={elem.title}
                content={html}
                withTransition={false}
                buttonlabel={elem.buttonlabel}
                imageUrl={elem.imageUrl}
              />
            );
          }
        }
      )}
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/offer/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            titles
            imageUrl
            buttonlabel
            parallaxImages { 
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed 
              direction 
              alt
            }
            listicon
            listtitle
            listcontent
            imageUrl
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
`;
