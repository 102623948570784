import React from "react";
import { ArrowTitleFrontMatter } from "types/graphql";
import ArrowBigRight from "assets/svg/arrow-big-right.svg";
import Section from "../section";
import {Parallax} from "react-scroll-parallax";

type arrowTitleProps = Omit<ArrowTitleFrontMatter, "format">;

const arrowTitle = ({ title }: arrowTitleProps) => {
  return (
    <Section full>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-16 gap-y-6">
        <div className="lg:col-span-3 col-start-1 col-end-1 flex" data-aos="custom" >
          <Parallax translateX={[0, 100]}>
           <ArrowBigRight className="w-44" />
          </Parallax>
        </div>
        <div className="lg:col-span-9">
          <span className="h1 mb-4">{title}</span>
        </div>
      </div>
    </Section>
  );
};

export default arrowTitle;
