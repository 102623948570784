import React, { useEffect, useState } from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { MethodTabsFrontMatter, skillProps } from "types/graphql";
import ArrowRight from "assets/svg/arrow-yellow-right.svg";
import Section from "../section";
import classNames from "classnames/bind";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper'

import 'swiper/css';

type tabSkillsProps = Omit<MethodTabsFrontMatter, "format">;

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/offer/skills/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            listcontent
          }
        }
      }
    }
  }
`;

const tabSkills = ({ title, listicon, listtitle, id }: tabSkillsProps) => {
  const skillsQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: skills },
  } = skillsQuery;

  const [value, setValue] = useState<number>(0);
  const [subvalue, setSubValue] = useState<number>(0);

  const filteredSection = skills.filter(
    (elem: skillProps) =>
      elem.node.frontmatter.section ==
      (listtitle ? listtitle[value] : undefined)
  );

  const handleClick = (item: string, index: number) => {
    const firstString = item.split(" ")[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    if ("plausible" in window) {
      window.plausible(`offre-${firstString}`);
    }
    setValue(index);
  }

  useEffect(() => {
    setSubValue(0)
  }, [value])

  return (
    <Section className="relative flex flex-col lg:pb-36" full>
      <div
        id={id}
        className="absolute left-0 -top-32 lg:-top-16 h-px w-px pointer-events-none opacity-0"
      />
      <span className="h2 mb-6 text-center block">{title}</span>
      <ul className="flex mb-6 lg:mb-16 gap-x-2 overflow-x-auto flex-nowrap pb-3 apperance-none no-scrollbar">
        {listtitle &&
          listtitle.map((item: string, index: number) => {
            return (
              <li
                role="button"
                key={index}
                className={classNames("inline-block flex-1 relative text-center shrink-0 min-w-[146px] whitespace-nowrap rounded hover:bg-yellow px-2 py-4 transition-all ease-ease", {
                  "bg-yellow" : index === value,
                  "bg-gray-100" : index !== value
                  })
                }
                onClick={() => handleClick(item, index)}
              >
                {listicon && (
                  <figure className="w-6 mb-1 mx-auto transition-all duration-05 ease-ease">
                    <img src={listicon[index]} alt="icon" />
                  </figure>
                )}
                <span className="caption1 transition-all duration-03 ease-ease text-center">
                  {item}
                </span>
              </li>
            );
          })}
      </ul>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={value}
          classNames='page-fade'
          timeout={200}
        >
          <div className="hidden lg:grid grid-cols-1 lg:grid-cols-5">
            <ul className="col-end-3 col-start-1">
              {filteredSection.map(
                ({ node: { frontmatter: elem } }: skillProps, index: number) => {
                  return (
                    <li
                      key={index}
                      role="button"
                      className={classNames(
                        "relative py-2 subtitle1 flex transition-all duration-05 ease-ease ",
                        {
                          "text-gray-800 active-arrow": index === subvalue,
                          "text-gray-500 no-arrow": index !== subvalue,
                        }
                      )}
                      onClick={() => setSubValue(index)}
                    >
                      <ArrowRight />
                      <span className="leading-7 transition-all duration-05 ease-ease hover:text-gray-800" dangerouslySetInnerHTML={{ __html: elem.title }}/>
                    </li>
                  );
                }
              )}
            </ul>
            <ul className="flex flex-col skill-card relative overflow-hidden rounded-xl border border-gray-100 py-8 pr-6 pl-8 lg:pl-11 w-full skills-list space-y-4 col-start-3 col-span-3 self-start">
              {filteredSection[subvalue]?.node.frontmatter.listcontent.map(
                (content: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className={classNames({
                        h4: index === 0,
                        "text-gray-800": index !== 0,
                      })}
                    >
                      {content}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </CSSTransition>
      </SwitchTransition>

      <div className={"lg:hidden"}>
        <Swiper
          speed={1000}
          autoHeight
          modules={[ Pagination ]}
          pagination={{
            el: '.pagination',
            type: 'bullets',
            bulletActiveClass: 'bg-yellow scale-200',
            bulletClass: 'bg-gray-200 rounded-full mx-2.5 w-1.5 h-1.5 transition duration-03 ease-ease inline-block',
            clickable: true
          }}
        >
          {filteredSection.map(
            ({ node: { frontmatter: elem } }: skillProps, index: number) => {
              let fullContent: string[] = [];
              filteredSection[index].node.frontmatter.listcontent.forEach(
                (content: string) => fullContent.push(content)
              );
              return (
                <SwiperSlide key={index}>
                  <div className="grid grid-cols-1 relative px-1 pb-8">
                    <li
                      role="button"
                      className="relative py-2 subtitle1 flex transition-all duration-05 ease-ease text-gray-800 active-arrow"
                      key={index}
                    >
                      <ArrowRight />
                      <span className="leading-7 transition-all duration-05 ease-ease">{elem.title}</span>
                    </li>
                    <ul className="flex flex-col skill-card relative overflow-hidden rounded-xl border border-gray-100 py-8 pr-4 pl-6  w-full skills-list space-y-4">
                      {fullContent.map((content, index) => {
                        return (
                          <li
                            key={index}
                            className={classNames({
                              h4: index === 0,
                              "text-gray-800": index !== 0,
                            })}
                          >
                            {content}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
        <div className="pagination  mb-20 text-center" />
      </div>
    </Section>
  );
};


export default tabSkills;
