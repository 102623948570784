import React, { useState } from "react";
import { ListAnchorsFrontMatter } from "types/graphql";
import ArrowRight from "assets/svg/arrow-right.svg";
import classNames from "classnames/bind";
import { isMobile } from "react-device-detect";
import { scroller } from 'react-scroll';

type anchorsProps = Omit<ListAnchorsFrontMatter, "format">;

const anchors = ({ listtitle }: anchorsProps) => {
  const [isActive, setActive] = useState<number>();

  const handleClick = (item: string, index: number) => {
    if ("plausible" in window) {
      window.plausible(`anchor-${item}`);
    }
    setActive(index);
    scroller.scrollTo(item, {
      duration: 600,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  return (
    <nav className={classNames('bg-white py-6 z-10 sticky top-16 lg:top-20', {
          'left-0 w-full': !isMobile,
          '-mt-4 mb-10': isMobile,
          })
        }
        data-aos="custom"
    >
      <ul className="flex justify-center gap-x-8 lg:gap-x-10">
        {listtitle.map((item: string, index: number) => {
          return (
            <li key={index}>
              <a
                onClick={() => handleClick(item, index)}
                className={classNames(
                  "cursor-pointer flex items-center text-gray-600 hover:text-gray-800", {
                    "text-gray-800": isActive === index,
                    "text-gray-600": isActive !== index,
                  }
                )}
              >
                <ArrowRight className="w-5 fill-current mr-1 lg:mr-2" />
                <span className="text-label2 lg:text-label1 uppercase font-bold leading-0">
                  {item}
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default anchors;
