import React, { useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { graphql, useStaticQuery } from "gatsby";
import { MethodTabsFrontMatter, SingleTechnoFrontMatter } from "types/graphql";
import Section from "../section";
import Accordion from "../accordion";
import classNames from "classnames/bind";

type tabTechnosProps = Omit<MethodTabsFrontMatter, "format">;

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/offer/technos/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            section
            icon
            title
            subtitle
          }
        }
      }
    }
  }
`;
const tabTechnos = ({ title, listicon, listtitle, id }: tabTechnosProps) => {
  const technosQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: technos },
  } = technosQuery;

  const [value, setValue] = useState<number>(0);
  const filteredTechnos = technos.filter(
    (techno: SingleTechnoFrontMatter) =>
      techno.node.frontmatter.section.toLowerCase() ==
      (listtitle ? listtitle[value].toLowerCase() : undefined)
  );

  const handleClick = (item: string, index: number) => {
    const firstString = item.split(" ")[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    if ("plausible" in window) {
      window.plausible(`techno-${firstString}`);
    }
    setValue(index);
  }

  return (
    <Section className="relative flex flex-col lg:pb-36 bg-gray-800 " full>
      <div
        id={id}
        className="absolute left-0 -top-32 lg:-top-16 h-px w-px pointer-events-none opacity-0"
      />
      <span className="h2 mb-6 text-white text-left block">{title}</span>
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-4">
        <ul className="lg:col-span-2 flex lg:flex-col gap-y-4 gap-x-6 pb-3 gap-x-2 overflow-x-auto flex-nowrap items-start">
          {listtitle &&
            listtitle.map((item: string, index: number) => {
              return (
                <li
                  key={index}
                  role="button"
                  className={classNames('inline-block relative flex items-center whitespace-nowrap rounded-sm hover:bg-white hover:text-gray-900  px-2 py-1 transition-all duration-03 ease-ease7', {
                    "bg-white" : index === value,
                    "text-gray-500" : index !== value
                    })
                  }
                  onClick={() => handleClick(item, index)}
                >
                  {listicon && listicon[index] && (
                    <figure className="w-5 mx-auto transition-all duration-03 ease-ease mr-2">
                      <img src={listicon[index]} alt="icon" />
                    </figure>
                  )}
                  <span className="subtitle1 text-center">{item}</span>
                </li>
              );
            })}
        </ul>

        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={value}
            classNames='page-fade'
            timeout={200}
          >
            <div className="lg:col-span-4 grid grid-cols-1 self-baseline">
              <dl className={value === 0 ? '' : 'grid lg:grid-cols-2 gap-x-10'}>
                {filteredTechnos.map(
                  (
                    { node: { frontmatter: elem, html } }: SingleTechnoFrontMatter,
                    index: number
                  ) => {

                    return (
                      <React.Fragment key={index}>
                      { value === 0
                        ?
                          <Accordion
                            index={`${value}-${index}`}
                            title={elem.title}
                            subtitle={elem.subtitle}
                            content={html}
                            icon={elem.icon}
                          />
                        :
                          <div className="border-b-gray-700 border-b mb-8 self-baseline">
                            <div className="flex items-center mb-6">
                              {elem.icon && (
                                <figure className="w-6 mr-2">
                                  <img src={elem.icon} alt="icon" />
                                </figure>
                              )}
                              <span className="h4 text-white"> {elem.title} </span>
                            </div>
                          </div>
                      }
                      </React.Fragment>
                    );
                  }
                )}
              </dl>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </Section>
  );
};

export default tabTechnos;
