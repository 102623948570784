import React from "react";
import { ListBannerFrontMatter, solutionProps } from "types/graphql";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../section";
import ImageContent from "./imageContent";

type listBannerProps = Omit<ListBannerFrontMatter, "format">

const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/offer/solutions/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            image
            title
            subtitle
            parallaxImages { 
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed 
              direction
              alt
            }
          }
        }
      }
    }
  }
`;

const listBannerContent = ({ title, id }: listBannerProps) => {
  const solutionsQuery = useStaticQuery(query);
  const {
    allMarkdownRemark: { edges: solutions },
  } = solutionsQuery;

  return (
    <Section data-name='list-number-content' className="relative bg-yellow gap-y-8" full>
      <div id={id} className="absolute left-0 -top-32 lg:-top-16 h-px w-px pointer-events-none opacity-0" />
      <span data-aos="custom" data-offset="3.8" className="h2 mb-6 text-center block">{title}</span>
      {solutions.map(({ node: { frontmatter: elem, html } } : solutionProps, index: number) => {
        return (
          <ImageContent
            key={index}
            title={elem.title}
            length={solutions.length}
            subtitle={elem.subtitle}
            className={"lg:py-16"}
            content={html}
            textFirst={true}
            parallaxImages={elem.parallaxImages}
            imageUrl={elem.image}
            reverse={index % 2 != 0}
          />
        );
      })}
    </Section>
  );
};

export default listBannerContent;
