import React, { useEffect, useState } from "react";
import ChevronDown from "../assets/svg/chevron-down.svg";
import classNames from "classnames/bind";
type propsAccordion = {
  icon?: string;
  title: string;
  subtitle?: string;
  content: string;
  index?: string
};

const Accordion = ({ title, content, icon, subtitle, index }: propsAccordion) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (index && index.indexOf('-0') > -1) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  },[index])

  return (
    <div className="border-b-gray-700 border-b mb-10">
      <dt
        className={classNames("cursor-pointer pb-5 outline-none")}
        role="button"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="flex items-center mb-2">
          {icon && (
            <figure className="w-6 mr-2">
              <img src={icon} alt={'logo ' + title} />
            </figure>
          )}
          <span className="h4 text-white"> {title} </span>
          <div
            className={
              "w-11 h-11  rounded-full flex items-center justify-center ml-auto transition-transform duration-05 ease-ease" +
              (isActive ? " bg-gray-500 rotate-180" : " bg-gray-700")
            }
          >
            <ChevronDown className="w-4" />
          </div>
        </div>
        <span className="text-white"> {subtitle} </span>
      </dt>
        <dd
          className={classNames("mb-4 text-gray-200 techno-content transition-all duration-03 ease-in-out overflow-hidden", {
            'max-h-0 ': !isActive,
            'max-h-screen	 ': isActive
          })}
          dangerouslySetInnerHTML={{ __html: content }}
        />
    </div>
  );
};

export default Accordion;
